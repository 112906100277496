@import '../index.scss';
@import '../breakpoints';

@include mobile-up {
    .LandingPage {
        text-align: center;
        color: $globalBg;
        height: 100vh;
        background-color: #c9d1d4;
        background-image: url('../assets/img/landingbg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        h1 {
            margin: 20px 0;
        }

        .logo {
            width: 60%;
            max-width: 300px;
            margin: 10vh 0 10vh;
        }

        .slogan {
            width: 60%;
            text-align: left;
            margin: 0 auto;
        }

        .loginButton {
            position: absolute;
            bottom: 6vh;
            width: 100%;
        }
    }
    
}