@import '../index.scss';
@import '../breakpoints';

@include mobile-up {
    body {
        background-color: $globalBg;
    }

    .DashboardPage {
        background-color: $globalBg;
        height: 100%;
        width: 100%;
        position: absolute;

        h1 {
            color: $globalText;
            margin-top: 16px;
            text-align: center;
        }

        .verticalCenter {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }

        .userAndGroups {
            width: 250px;
            margin: 0 auto 0;
            overflow: hidden;

            .avatar {
                cursor: pointer;
            }

            .createGroup {
                float: right;
                padding-top: 7px;
                cursor: pointer;

                img {
                    width: 60px;
                }
            }
        }

        .recognizeMusicButton {
            box-sizing: border-box;
            height: 250px;
            width: 250px;
            margin: 14px auto 0;
            border-radius: 500px;
            background: white;
            text-align: center;
            color: $globalBg;
            padding-top: 74px;
            cursor: pointer;
        }

        .playlistButton {
            position: absolute;
            bottom: 5px;
            right: 5px;
        }

        .myHistory {
            width: 250px;
            margin: -34px auto 0;
            overflow: hidden;
            cursor: pointer;

            >div {
                float: right;

                img {
                    width: 40px;
                }
            }
        }
    }
}

@include tablet-landscape-up {
    .DashboardPage {
        .verticalCenter {
            position: relative;
            width: 100%;
            top: auto;
            transform: unset;
            margin-top: 20px;
        }

        .userAndGroups {
            width: 80%;
        }

        .recognizeMusicButton {
            margin-top: 50px;
        }
    }
}