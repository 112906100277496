@import '../index.scss';
@import '../breakpoints';

@include mobile-up {
    .playlistEntry {
        overflow: hidden;

        .coverWrapper {
            margin: 10px 0;
            width: 86px;
            height: 100px;
            overflow: hidden;
            float: left;
            position: relative;
        }

        .person {
            float: left;
            box-sizing: border-box;
            width: calc(100% - 86px);
            padding: 14px 10px;
            position: relative;
            color: $globalText;

            h3 {
                box-sizing: border-box;
                width: calc(100% - 100px);
                line-height: 26px;
                font-size: 24px;
                color: $globalText;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                float: left;
                margin-top: 22px;
            }

            .score {
                box-sizing: border-box;
                height: 40px;
                width: 100px;
                margin-top: 16px;
                background: $lightYellow;
                color: $globalBg;
                border-radius: 4px;
                text-align: center;
                float: right;
                font-size: 20px;
                line-height: 40px;
                font-weight: bold;
            }
        }
    }
}