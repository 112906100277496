@import '../index.scss';
@import '../breakpoints';

@include mobile-up {
    .songList {
        position: absolute;
        width: 90%;
        margin: 0 auto;
        height: 70vh;
        max-height: 450px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid #aaa;
        border-radius: 8px;
        background: $globalBg;
        z-index: 10;
        color: $globalText;
        text-align: center;
        overflow: hidden;

        .closeButton {
            position: absolute;
            top: 6px;
            right: 6px;
            height: 32px;
            width: 32px;
            padding-top: 4px;
            text-align: center;
            cursor: pointer;
        }

        h2 {
            margin-top: 40px;
            padding: 0 5px;
            color: $globalText;
            text-align: center;
            font-size: 28px;
            margin-bottom: 30px;
        }

        .songs {
            height: calc(70vh - 110px);
            max-height: calc(450px - 100px);
            overflow-y: scroll;
            scrollbar-width: none;
            text-align: left;
            padding: 0px 14px;

            .song {
                margin: 0 5%;
                h3 {
                    color: $globalText;
                    text-align: left;
                    font-size: 24px;
                    line-height: 28px;
                }

                .artist {
                    color: $globalText;
                    padding-left: 10px;
                    font-size: 12px;
                }

                &::after {
                    content: "";
                    box-sizing: border-box;
                    display: block;
                    width: 8px;
                    height: 4px;
                    background-color: #ffffff22;
                    border: 1px solid #ffffff22;
                    border-radius: 10px;
                    margin: 3px 0;
                    margin-left: calc(50% - 8px);
                }

                &:last-of-type::after {
                    content: "";
                    height: 0;
                    width: 0;
                    border: 0;
                }
            }
        }
    }
}