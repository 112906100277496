@import '../index.scss';
@import '../breakpoints';

@include mobile-up {
    .PlaylistPage {
        background-color: $globalBg;
        height: 100%;
        width: 100%;
        position: absolute;

        h1 {
            color: $globalText;
            margin: 16px 0;
            text-align: center;
        }

        .scrollContainer {
            height: calc(100% - 30px);
            overflow-y: scroll;
            scrollbar-width: none;
            padding-bottom: 100px;

            ul {
                margin-bottom: 70px;
            }
        }
    }
}
